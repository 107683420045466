<template>
  <el-container style="height: 100vh;">
    <el-aside style="min-width:15%">
      <el-menu router :default-active="$route.path">
        <div class="logo">
          <img src="@/assets/img/logo.png" alt="" />
        </div>
        <el-submenu index="1">
          <template slot="title"
            ><i class="el-icon-s-operation"></i>寄宿家庭信息管理</template
          >
          <el-menu-item-group v-if='$cookie.get("status") == 1'>
            <el-menu-item index="/layouts/list"> 寄宿家庭信息列表</el-menu-item>
            <el-menu-item index="/layouts/addlist">新增/编辑寄宿家庭</el-menu-item>
            <el-menu-item index="/layouts/contract">签约合同列表</el-menu-item>
            <el-menu-item index="/layouts/matchinglist">顾问匹配申请列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-else>
            <el-menu-item index="/layouts/matchingform">匹配申请</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>
    <el-container class="right">
      <Header />
      <main>
        <Breadcrumb />
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
      </main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/header/index.vue";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
export default {
  components: { Header, Breadcrumb },
  name: "AppLayout",
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(20).fill(item),
    };
  },
  methods: {
    handleChange(v) {
      console.log("tab value:", v);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-aside {
  min-height: 800px;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafafa;
  overflow: hidden;
  .el-menu {
    height: 100%;
    background: #079562;
    border-radius: 20px;
    color: white;
    .el-menu--inline {
      background: white;
      border-radius: 0px;
    }
    .el-submenu__title {
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: rgba(7, 149, 98, 0.2);
      }
      i {
        color: white;
        font-size: 18px;
      }
    }
    .el-menu-item-group{
      .el-menu-item-group__title{
        padding: 0;
      }
      .el-menu-item{
        position: relative;
        display: flex;
        align-items: center;
        >i{
          opacity: 0;
        }
      }
      .is-active{
        position: relative;
        >i{
          opacity: 1;
        }
      }
    }
    .logo {
      width: 100%;
      height: 152px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        height: 61px;
      }
    }
  }
}
.right{
  flex-direction: column;
  background: #fafafa;
  padding-left: 10px;
  min-width: 1200px;
  min-height: 800px;
}
main{
  padding-right: 40px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
