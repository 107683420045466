<template>
  <div class="view">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
      v-for="(item, key) in breadList"
      :key="key"
      :to="{ path: item.path }"
      >{{ item.meta.title }}</el-breadcrumb-item
    >
    </el-breadcrumb>
    <div class="title">寄宿家庭信息管理</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: [], // 路由集合
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    isHome(route) {
      return route.name === "home";
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      // console.log(matched)
      //如果不是首页
      // if (!this.isHome(matched[0])) {
      //   matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      //   matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      // }
      this.breadList = matched;
      
    },
  },
  created() {
    this.getBreadcrumb();
  },
}
</script>

<style scoped lang='scss'>
.view{
  width: 100%;
  height: 220px;
  margin: 26px 0px;
  background: url('../../assets/img/top.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 34px;
  box-sizing: border-box;
  color: white;
  >.title{
    font-size: 40px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 20px;
  }
  /deep/ .el-breadcrumb{
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    .el-breadcrumb__inner{
      color: white;
    }
  }
}
</style>