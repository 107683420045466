<template>
  <el-header>
    <div class="lerf"></div>
    <div class="right">
      <img :src='$cookie.get("avatar") || tx' alt="" />
      <span>{{$cookie.get("name") || 'Lele'}}</span>
      <el-button type="primary" icon="el-icon-switch-button" @click="quit"
        >退出</el-button
      >
    </div>
  </el-header>
</template>

<script>
export default {
  data() {
    return {
      tx: require('@/assets/img/tx.png')
    }
  },
  methods: {
    quit() {
      this.$cookie.remove("token");
      this.$cookie.remove("name");
      this.$cookie.remove("avatar");
      this.$cookie.remove("status");
      this.$router.push("/login");
      this.$message({
        showClose: true,
        message: "退出登录",
        type: "success",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-header {
  width: 100%;
  height: 78px !important;
  background-color: #fafafa;
  color: #2f2f32;
  border-bottom: 1px solid #d8d8d8;
  line-height: 60px;
  padding-right: 60px;
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
    align-items: center;
    > img {
      height: 46px;
      margin: 0 10px;
      border-radius: 100px;
      object-fit: cover;
    }
    > span {
      font-size: 14px;
      font-weight: 400;
      color: #1e1e1e;
    }
    /deep/ .el-button {
      background: #e3f2ed;
      margin-left: 40px;
      border-color: #e3f2ed;
      color: #0d9e72;
      font-weight: 700;
      > i {
        font-weight: 700;
      }
    }
  }
}
</style>
